var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"self_sec"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"schedule_form py_60 mt_10"},[_c('h2',{staticClass:"text_black font_size_36 mb_40",domProps:{"innerHTML":_vm._s(_vm.schedule_assessment_title)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.schedule_assessment_description)}}),_c('iframe',{staticStyle:{"width":"100%","height":"1080px","margin-top":"67px"},attrs:{"src":_vm.company_data.formlink_schedule_request +
                '?firstname=' +
                this.user_data.first_name +
                '&lastname=' +
                this.lastName +
                '&email=' +
                this.user_data.email,"frameborder":"0"}})])]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticClass:"self_img"},[_c('img',{attrs:{"src":require("../assets/images/schedule_img.png"),"alt":"img"}})])])
}]

export { render, staticRenderFns }