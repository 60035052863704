<template>
  <div>
    <!-- self-assessment signup start -->
    <section class="self_sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="schedule_form py_60 mt_10">
              <h2
                class="text_black font_size_36 mb_40"
                v-html="schedule_assessment_title"
              ></h2>
              <div v-html="schedule_assessment_description"></div>

              <iframe
                :src="
                  company_data.formlink_schedule_request +
                  '?firstname=' +
                  this.user_data.first_name +
                  '&lastname=' +
                  this.lastName +
                  '&email=' +
                  this.user_data.email
                "
                frameborder="0"
                style="width: 100%; height: 1080px; margin-top: 67px"
              ></iframe>
            </div>
          </div>
          <div class="col-md-6 p-0">
            <div class="self_img">
              <img src="../assets/images/schedule_img.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- sel assessment signup snd -->
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "schedule_eval",
  data() {
    return {
      // company_data : null,
      company_data: this.$store.getters["auth/authUser"].client ?? null,
      user_data: [],
      lastName: "",
      schedule_assessment_title: "Schedule your ergonomic assessment",
      schedule_assessment_description: `<p class="text_black font_size_22 mb_28">
                        Our ergonomists are here to help you feel (and work) your best.
                        Once you fill out the form, we will contact you shortly to
                        schedule your appointment! We look forward to working with you
                        and getting you comfy.
                      </p>`,
    };
  },
  components: {
    // carousel
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    let data = this.$store.getters["auth/authUser"];
    this.company_data = data.client;
    this.user_data = data.user;
    this.lastName = this.user_data.last_name ? this.user_data.last_name : "";

    if (this.company_data.site_menu_details.RequestAnEvaluation.status == 1) {
      this.schedule_assessment_title = this.company_data
        .schedule_assessment_title
        ? this.company_data.schedule_assessment_title
        : this.schedule_assessment_title;
      this.schedule_assessment_description = this.company_data
        .schedule_assessment_description
        ? this.company_data.schedule_assessment_description
        : this.schedule_assessment_description;
    }
  },
};
</script>
